<template>
<div>
    <v-dialog v-model="readmore_pdpa">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span>นโยบายคุ้มครองข้อมูลส่วนบุคคล</span>
                <v-btn class="mt-1" @click="readmore_pdpa = false" elevation="0" icon x-large style="color:#a84192;">
                    ปิด<v-icon color="#a05c92">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p><b>คำประกาศนโยบายความเป็นส่วนตัว</b>
                    <br>
                    <b>(Privacy Notice) </b>
                </p>
                &nbsp;&nbsp;&nbsp;&nbsp;บริษัท เอไซ (ประเทศไทย) มาร์เก็ตติ้ง จำกัด ซึ่งต่อไปนี้จะเรียกว่า “บริษัทฯ” มุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของผู้ใช้บริการ คู่ค้า พันธมิตรทางธุรกิจ (เรียกรวมกันว่า “ท่าน” หรือ “เจ้าของข้อมูล”) ไม่ว่าจะเป็นการเก็บรวบรวม ใช้ และ/หรือเปิดเผย (เรียกรวมกันว่า “การประมวลผลข้อมูล”) เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 บริษัทฯ จึงจัดทำนโยบายฉบับนี้ขึ้นเพื่อชี้แจงรายละเอียดเกี่ยวกับ สิทธิและหน้าที่ ตลอดจนเงื่อนไขต่าง ๆ ในการเก็บ รวมรวบ ใช้ และเปิดเผยข้อมูลส่วนบุคคลให้เจ้าของข้อมูลทราบ
                <br>
                &nbsp;บริษัทฯ ขอแนะนำให้ท่านโปรดอ่านและทำความเข้าใจคำประกาศนโยบายความเป็นส่วนตัว (Privacy Notice) นี้เพื่อรับทราบวัตถุประสงค์ที่บริษัทฯ ได้เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน ระยะเวลาในการเก็บข้อมูล การทำลายข้อมูล ตลอดจนสิทธิของเจ้าของข้อมูลส่วนบุคคล ซึ่งท่านสามารถศึกษารายละเอียดได้ดังต่อไปนี้ <br><br>
                <b>ข้อมูลส่วนบุคคล</b>
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;“ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;“ข้อมูลที่มีความอ่อนไหว” หมายถึง ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ เช่น ข้อมูลจำลองใบหน้า ข้อมูลจำลองม่านตา หรือข้อมูลจำลองลายนิ้วมือ
                <br><br>
                <b>ข้อมูลส่วนบุคคลที่บริษัทฯเก็บรวบรวม</b>
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;ในการเก็บรวบรวม และเก็บรักษาข้อมูลส่วนบุคคล บริษัทฯ จะใช้วิธีการที่ชอบด้วยกฎหมายและจำกัดเพียงเท่าที่จำเป็นตามวัตถุประสงค์การดำเนินงานของบริษัทฯ อันประกอบด้วย
                <br><br>1.ข้อมูลส่วนตัว เช่น ชื่อ-นามสกุล, อายุ, วันเกิด, เพศ, เลขประจำตัวประชาชน, เลขที่หนังสือเดินทาง, หมายเลขที่สามารถระบุตัวตนได้
                <br>2.ข้อมูลสำหรับติดต่อ เช่น ที่อยู่, หมายเลขโทรศัพท์, อีเมล
                <br>3.ข้อมูลการเข้ารับบริการ เช่น ข้อมูลการนัดหมายแพทย์ ข้อมูลส่วนบุคคลของญาติ และบริการเสริมอื่นๆ
                <br>4.ข้อมูลการเข้าร่วมกิจกรรมทางการตลาด เช่น ข้อมูลการลงทะเบียนเพื่อร่วมกิจกรรม
                <br>5.ข้อมูลสถิติ เช่น จำนวนผู้ป่วย ผู้ดูแล ผู้ให้บริการ และการเข้าชมเว็บไซต์
                <br>6.ข้อมูลด้านสุขภาพ รายงานที่เกี่ยวกับสุขภาพกาย และสุขภาพจิต การดูแลสุขภาพของท่าน ผลการทดสอบจากห้องทดลอง และการวินิจฉัย
                <br>7.ข้อมูลที่เกี่ยวข้องกับการใช้ยาและการแพ้ยาของท่าน
                <br>8.ข้อมูล Feedback และผลการรักษาที่ท่านได้ให้ไว้
                <br>9.ข้อมูลที่ได้จากระบบอัตโนมัติหรืออุปกรณ์ต่าง ๆ เช่น หมายเลข IP Address, Cookie, พฤติกรรมการใช้บริการ, ประวัติการใช้บริการ, เสียง, ภาพถ่าย, ภายเคลื่อนไหว, ชื่อบัญชี Social Media, Chat, Geolocation
                <br><br>โดยบริษัทฯ จะดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลเมื่อได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลก่อน ยกเว้นในกรณีดังต่อไปนี้
                <br><br>1.เพื่อปฏิบัติตามสัญญา กรณีการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อความจำเป็นต่อการให้บริการหรือปฏิบัติตามสัญญาระหว่างเจ้าของข้อมูลและบริษัทฯ
                <br>2.เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพ
                <br>3.เพื่อปฏิบัติตามกฎหมาย
                <br>4.เพื่อผลประโยชน์อันชอบโดยกฎหมายของบริษัทฯ กรณีมีความจำเป็นเพื่อประโยชน์อันชอบธรรมในการดำเนินงานของบริษัทฯ โดยบริษัทฯ จะพิจารณาถึงสิทธิของเจ้าของข้อมูลเป็นสำคัญ เช่น เพื่อป้องกันการฉ้อโกง การรักษาความปลอดภัยในระบบเครือข่าย การปกป้องสิทธิเสรีภาพ และประโยชน์ของเจ้าของข้อมูลเป็นต้น
                <br>5.เพื่อการศึกษาวิจัยหรือสถิติ กรณีที่มีการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะหรือที่เกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิ และเสรีภาพของเจ้าของข้อมูล
                <br>6.เพื่อปฏิบัติภารกิจของรัฐ กรณีมีความจำเป็นต่อการปฏิบัติตามภารกิจเพื่อประโยชน์สาธารณะ หรือการปฏิบัติหน้าที่ตามอำนาจรัฐที่ บริษัทฯ ได้รับมอบหมาย
                <br><br><b>แหล่งที่มาของข้อมูล</b>
                <br>1.ข้อมูลจากเจ้าของข้อมูลโดยตรง ที่ท่านได้ให้ไว้ในการใช้บริการ ผ่านช่องทางเว็บไซต์ แอพพลิเคชั่นเช็คหมอง LineOA หรือช่องทางอื่นของบริษัทฯ เช่น การนัดหมายแพทย์ การทำธุรกรรมแบบออนไลน์ การสมัครรับจดหมายข่าว การรับความช่วยเหลือพิเศษ รวมถึงการทำธุรกรรมแบบออฟไลน์ หรือจากความสมัครใจของท่านในการทำแบบสอบถาม (Survey) หรือการตอบโต้ทางจดหมายอิเล็กทรอนิกส์ (e-mail) หรือการกรอก/ให้ข้อมูลประกอบการสมัครงาน หรือช่องทางการสื่อสารอื่นๆ ระหว่างบริษัทฯและท่าน
                <br>2.ข้อมูลจากแหล่งอื่น เช่น ข้อมูลสาธารณะ ข้อมูลจากหน่วยงานพันธมิตร บริษัทในเครือ ตัวแทนจำหน่ายหรือผู้ให้บริการของบริษัทฯ ตลอดจนข้อมูลส่วนบุคคลอื่น ๆ ที่ผู้ใช้บริการได้มอบไว้ให้
                <br><br><b>วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล</b>
                &nbsp;&nbsp;บริษัทฯ อาจนำข้อมูลส่วนบุคคลไปใช้ตามวัตถุประสงค์ ดังต่อไปนี้หรือตามวัตถุประสงค์อื่น ๆ ที่แจ้งขณะเก็บรวบรวมข้อมูล หรือที่ท่านได้ให้ความยินยอมหลังจากบริษัทฯ ดำเนินการเก็บข้อมูลไปแล้ว
                <br><br>1.จัดหาบริการ หรือส่งมอบบริการของบริษัทฯ และการเข้าถึงบริการของท่าน ไม่ว่าทางออนไลน์หรือออฟไลน์
                <br>2.นัดหมายแพทย์ ส่งข่าวสาร แนะนำบริการของบริษัทฯ
                <br>3.การยืนยันตัวตนผู้ป่วย
                <br>4.ส่งข้อความแจ้งเตือนการนัดหมายแพทย์ หรือการเสนอความช่วยเหลือจากบริษัทฯ
                <br>5.ใช้ในการดำเนินกิจการ ประเมินผล และปรับปรุงธุรกิจ เพื่อพัฒนาคุณภาพบริการ
                <br>6.เพื่อประโยชน์อันชอบด้วยกฎหมายของบริษัทฯ เช่น การบันทึกเสียงการร้องเรียน, การรักษาความปลอดภัย
                <br>7.การปฏิบัติตามกฎของบริษัทฯ
                <br>8.ใช้ในการสอบสวนและปฏิบัติตามกฎหมาย ข้อบังคับ หรือหน้าที่ตามกฎหมายของบริษัทฯ
                <br>9.เป็นช่องทางในการสื่อสาร ตอบคำถามหรือตอบสนองต่อคำขอของท่าน เช่น การรับเรื่องร้องเรียน ปัญหาการใช้บริการ
                <br>10.อำนวยความสะดวกและนำเสนอรายการสิทธิประโยชน์ต่าง ๆ แก่ผู้ใช้บริการ
                <br>11.จุดประสงค์ด้านการตลาด การส่งเสริมการขาย และการลูกค้าสัมพันธ์ เช่น การส่งข้อมูลเกี่ยวกับโปรโมชั่น ผลิตภัณฑ์และบริการ รายการส่งเสริมการขาย และธุรกิจพันธมิตร
                <br>12.สำรวจความพึงพอใจของผู้ใช้บริการ วิจัยตลาด และวิเคราะห์ทางสถิติ เพื่อเป็นข้อมูลในการปรับปรุงผลิตภัณฑ์และบริการ หรือสร้างสรรค์ผลิตภัณฑ์และบริการใหม่ ๆ
                <br><br><b>การส่งต่อและเปิดเผยข้อมูลส่วนบุคคล</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ อาจทำการเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกเพื่อให้เป็นไปตามวัตถุประสงค์ที่เกี่ยวข้องกับการประมวลผลข้อมูลส่วนบุคคล ในกรณีดังต่อไปนี้
                <br><br>1.เปิดเผยหรือแบ่งปันข้อมูลเฉพาะเท่าที่จำเป็นแก่ธุรกิจ ผู้ให้บริการ หรือหน่วยงานภายนอก ดังต่อไปนี้
                <br>1.1.ธุรกิจคู่ค้า และธุรกิจพันธมิตร
                <br>1.2.ตัวแทน หรือคู่ค้าที่ให้บริการแก่บริษัทฯ หรือดำเนินการใด ๆ ในฐานะตัวแทนของบริษัทฯ เช่น ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ รวมถึงบริษัทผู้ดูแลข้อมูลและจัดทำแอพพลิเคชั่น เช็คหมอง
                <br>1.3.หุ้นส่วนทางธุรกิจ เช่น พันธมิตรที่เข้าร่วมรายการโปรแกรมสะสมคะแนนและสิทธิประโยชน์ และบริษัทอื่น ๆ ที่เกี่ยวข้องในการบริการแก่ท่าน หรือตอบสนองตามวัตถุประสงค์ข้างต้น
                <br><br>ทั้งนี้ บริษัทฯ จะจัดทำข้อตกลงการประมวลผลข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด
                <br>2.บริษัทฯ อาจเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลให้แก่ บริษัทในเครือ โดยจะเป็นการประมวลผลข้อมูลภายใต้วัตถุประสงค์ที่ระบุในนโยบายความเป็นส่วนตัวฉบับนี้เท่านั้น
                <br>3.กฎหมายหรือกระบวนการทางกฎหมายบังคับให้เปิดเผยข้อมูล หรือเปิดเผยต่อเจ้าพนักงาน เจ้าหน้าที่รัฐ หรือหน่วยงานที่มีอำนาจเพื่อปฏิบัติตามคำสั่งหรือคำขอที่ชอบด้วยกฎหมาย
                <br><br><b>การถ่ายโอนหรือส่งต่อข้อมูลไปยังต่างประเทศ</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ อาจจะส่งหรือโอนข้อมูลส่วนบุคคลไปยัง ต่างประเทศโดยจะทำให้แน่ใจว่าประเทศปลายทางหรือหน่วยงานปลายทางมีมาตรฐานและนโยบายในการคุ้มครองความเป็นส่วนตัวที่เพียงพอ
                <br><br><b>การปกป้องข้อมูลส่วนบุคคล</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ ได้จัดทำและ/หรือเลือกใช้ระบบการจัดเก็บข้อมูลส่วนบุคคลให้มีกลไกและเทคนิคที่เหมาะสม พร้อมทั้งมีมาตรการรักษาความมั่นคงปลอดภัยตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล และกฎหมายที่เกี่ยวข้อง รวมทั้งจำกัดการเข้าถึงข้อมูลส่วนบุคคลของท่านจากพนักงาน ลูกจ้าง และตัวแทนของบริษัทฯ เพื่อป้องกันไม่ให้ข้อมูลส่วนบุคคลของท่านถูกนำไปใช้ เปิดเผย ทำลาย หรือเข้าถึงโดยไม่ได้รับอนุญาต
                <br><br><b>ระยะเวลาในการจัดเก็บข้อมูล</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการไว้ตลอดระยะเวลาตราบเท่าที่จำเป็นต่อการประมวลผลข้อมูลส่วนบุคคลตามวัตถุประสงค์ในนโยบายนี้เท่านั้น และบริษัทฯ สงวนสิทธิเก็บข้อมูลไว้อีกเป็นระยะเวลา 5 ปี หลังจากผู้ใช้บริการยกเลิกการใช้บริการ เพื่อประโยชน์ในการปกป้อง และต่อสู้สิทธิต่าง ๆ ของบริษัทฯ เว้นแต่กฎหมายที่เกี่ยวข้องกำหนดให้บริษัทฯ จำเป็นต้องเก็บข้อมูลส่วนบุคคลไว้เป็นระยะเวลาอื่น บริษัทฯ อาจมีความจำเป็นต้องเก็บข้อมูลส่วนบุคคลไว้เป็นระยะเวลาเกินกว่าที่ระบุไว้
                <br><br><b>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ อาจแก้ไขเพิ่มเติมนโยบายความเป็นส่วนตัวโดยจะประกาศบนเว็บไซต์ https://www.checkmong.com พร้อมระบุวันที่แก้ไขเพิ่มเติมครั้งล่าสุด บริษัทฯ แนะนำให้ท่านตรวจสอบนโยบายนี้เป็นประจำ โดยการที่ท่านใช้ผลิตภัณฑ์หรือบริการทางเว็บไซต์ของบริษัทฯ ต่อไปหลังจากที่มีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวจะถือว่าท่านยอมรับนโยบายที่เปลี่ยนแปลงนั้นแล้ว
                <br><br><b>สิทธิของเจ้าของข้อมูลส่วนบุคคล</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;ท่านสามารถขอใช้สิทธิต่าง ๆ ตามที่กฎหมายกำหนด และตามที่ระบุไว้ในประกาศฉบับนี้ได้ดังต่อไปนี้
                <br>1.สิทธิในการขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล
                <br>2.สิทธิในการขอแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันและถูกต้อง
                <br>3.สิทธิในการขอรับข้อมูลส่วนบุคคล ในกรณีที่บริษัทฯได้ทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
                <br>4.สิทธิในการขอลบหรือทำลายหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้ เมื่อข้อมูลนั้นหมดความจำเป็นหรือเมื่อเจ้าของข้อมูลส่วนบุคคลถอนความยินยอม
                <br>5.สิทธิในการขอระงับการใช้ข้อมูลส่วนบุคคล ในกรณีเมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบหรือเมื่อข้อมูลดังกล่าวหมดความจำเป็น
                <br>6.สิทธิในการถอนความยินยอม ในการประมวลผลข้อมูลที่ผู้ใช้บริการเคยให้ไว้
                <br>7.สิทธิในการขอคัดค้าน เจ้าของข้อมูลส่วนบุคคลมีสิทธิคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลเมื่อใดก็ได้
                <br><br>&nbsp;&nbsp;&nbsp;&nbsp;หากท่านมีข้อสงสัยหรือมีความประสงค์จะแก้ไข ลบข้อมูล ใช้สิทธิ หรือติดต่อเรื่องอื่น ๆ ที่เกี่ยวกับข้อมูลของท่าน กรุณาติดต่อผ่านช่องทางการติดต่อด้านล่าง
                <br><br><b>ช่องทางการติดต่อ</b>
                <br>&nbsp;&nbsp;&nbsp;&nbsp;หากท่านต้องการติดต่อหรือมีข้อสงสัยหรือต้องการสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคล การใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล หรือมีข้อร้องเรียนใด ๆ ท่านสามารถติดต่อบริษัทฯ ได้ดังช่องทางต่อไปนี้
                <br><br>ชื่อบริษัท บริษัท เอไซ (ประเทศไทย) มาร์เก็ตติ้ง จำกัด
                <br><br>ที่อยู่ อาคารจีพีเอฟ วิทยุ ทาวเวอร์ เอ ชั้น 6
                <br><br>93/1 ถนนวิทยุ แขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330
                <br><br>หมายเลขโทรศัพท์ 0 2256 6296
                <br><br>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                <br>อีเมล์ dpo@eisaith.com
                <br><br>เว็บไซต์ www.eisai.co.th
            </v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn class="mt-1" @click="readmore_pdpa = false" elevation="0" icon x-large style="color:#a84192;">
        ปิด<v-icon color="#a05c92">cancel</v-icon>
    </v-btn>
</v-card-actions>
        </v-card>

    </v-dialog>
    <v-row class="align-center d-flex h-0">
        <v-col lg="4" sm="7" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="mb-3 ml-4 font-weight-bold text-primary">
                            <br>
                            <v-img src="@/assets/logo.png" width="25" style="border-radius: 256px; margin-bottom:5px;"></v-img>

                            <span>นโยบายคุ้มครองข้อมูลส่วนบุคคล</span>
                            <!-- {{ windowHeight }} -->
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle
              >Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle
            > -->
                        
                        <v-card-text id="pdpa_box_id" ref="pdpa_box" style="
                  background-color: #f8f8f8;
                  border-radius: 7px;
                " >
                <!-- :style="{ 'height': (windowHeight-padding_bottom) + 'px' }" -->
                            <v-row align="center">
                                <v-col class="text--primary" cols="12" style="text-align: justify;">
                                    <p><b>คำประกาศนโยบายความเป็นส่วนตัว</b>
                                        <br>
                                        <b>(Privacy Notice) </b>
                                    </p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;บริษัท เอไซ (ประเทศไทย) มาร์เก็ตติ้ง จำกัด ซึ่งต่อไปนี้จะเรียกว่า “บริษัทฯ” มุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของผู้ใช้บริการ คู่ค้า พันธมิตรทางธุรกิจ (เรียกรวมกันว่า “ท่าน” หรือ “เจ้าของข้อมูล”) ไม่ว่าจะเป็นการเก็บรวบรวม ใช้ และ/หรือเปิดเผย (เรียกรวมกันว่า “การประมวลผลข้อมูล”) เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 บริษัทฯ จึงจัดทำนโยบายฉบับนี้ขึ้นเพื่อชี้แจงรายละเอียดเกี่ยวกับ สิทธิและหน้าที่ ตลอดจนเงื่อนไขต่าง ๆ ในการเก็บ รวมรวบ ใช้ และเปิดเผยข้อมูลส่วนบุคคลให้เจ้าของข้อมูลทราบ
                                    <br>
                                    &nbsp;บริษัทฯ ขอแนะนำให้ท่านโปรดอ่านและทำความเข้าใจคำประกาศนโยบายความเป็นส่วนตัว (Privacy Notice) นี้เพื่อรับทราบวัตถุประสงค์ที่บริษัทฯ ได้เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน ระยะเวลาในการเก็บข้อมูล การทำลายข้อมูล ตลอดจนสิทธิของเจ้าของข้อมูลส่วนบุคคล ซึ่งท่านสามารถศึกษารายละเอียดได้ดังต่อไปนี้
                                    <br>                                    <br>
                                    <p style="text-align:center;">
                                        <a href="#" @click="readmorePdpa">>> อ่านประกาศข้อมูลส่วนบุคคลฉบับเต็มได้ที่นี่ <<</a> 
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <!-- {{scroll_height}}
                        {{current_scroll}}
                        {{scroll_height-current_scroll}}
                        {{windowHeight}}
                        {{scroll_height-current_scroll-windowHeight}} -->
                        <!-- {{scroll_height}}
                        {{current_scroll}}
                        {{scroll_height-current_scroll}}
                        <v-checkbox v-model="pdpa_accept" color="#141727" label="ยินยอมโดยสมัครใจ (Accept)" :ripple="false" class="ma-0 mt-2 checkbox-custom checkbox-thinner">
                        </v-checkbox> -->
                    </v-list-item-content>
                </v-list-item>

            </v-card>

        </v-col>

    </v-row>
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px;">
        <v-btn outlined @click="goBack" color="purple" class="border-radius-xl">
            ไม่ยินยอม
        </v-btn>

        &nbsp; &nbsp; &nbsp; &nbsp;

        <v-btn style="width: 188px" color="bg-primary text-white" class="border-radius-xl" :disabled="!pdpa_accept" @click="checkPdpa">
            ยินยอม (Accept)
        </v-btn>
    </v-row>
    <v-row v-show="!$vuetify.breakpoint.mobile"></v-row>
    <v-row style="background-color: white;
    padding-bottom: 67px;" v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn outlined @click="goBack" color="purple" class="border-radius-xl">
                ไม่ยินยอม
            </v-btn>

            &nbsp; &nbsp; &nbsp; &nbsp;

            <v-btn style="width: 188px" color="bg-primary text-white" class="border-radius-xl" :disabled="!pdpa_accept" @click="checkPdpa">
                ยินยอม (Accept)
            </v-btn>
        </v-row>
    </v-footer>

</div>
</template>

<script>
export default {
    name: "register-select-type",
    data() {
        return {
            windowHeight: window.innerHeight,
            current_scroll: '',
            scroll: '',
            scroll_height: '',
            pdpa_accept: true,
            readmore_pdpa: false,
            padding_bottom: 200,
        };
    },
    // beforeCreate() {
    //     // this.$store.commit('initialiseStore');
    //     this.$store.commit('initialiseStore');
    // },
    mounted() {
        // //console.log(this.$refs.myButton);
        // //console.log(document.getElementById('pdpa_box_id').scrollHeight)
        // //console.log(document.getElementById('pdpa_box_id').scrollTop)
        //console.log(this.$refs.pdpa_box.scrollHeight)
        // this.scroll_height = this.$refs.pdpa_box.scrollHeight

        this.scroll = this.$refs.pdpa_box
        this.scroll_height = this.scroll.scrollHeight
        this.scroll.addEventListener("scroll", event => {
            this.current_scroll = this.scroll.scrollTop
        }, {
            passive: true
        });

        // var scrollDemo = document.getElementById('pdpa_box').scrollTop
        // scrollDemo.addEventListener("scroll", event => {
        //     output.innerHTML = `scrollTop: ${scrollDemo.scrollTop} <br>
        //                         scrollLeft: ${scrollDemo.scrollLeft} `;
        // }, { passive: true });
        // this.current_scroll = document.getElementById('pdpa_box').scrollTop
        // //console.log(this.$ref.textbox)
        //console.log(localStorage.getItem('store'))
        //console.log(this.$store.state)
        this.pdpa_update()

        
        window.addEventListener('resize', this.onResize);
        
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    watch: {
        current_scroll(val) {
            var cr_scroll = Math.floor((this.scroll_height-val-this.windowHeight))
            var pd_bt = Math.floor((-1*this.padding_bottom))

            
            // //console.log('cr_scroll->',cr_scroll)
            // //console.log('pd_bt->',pd_bt)
            if(cr_scroll <= pd_bt){
                //console.log('77777')
                this.pdpa_accept = true
            }
            // if ((this.scroll_height-val-this.windowHeigh) <= (-1*this.padding_bottom)+10) {
            //     this.pdpa_accept = true
            //     // this.checkPdpa()
            //     //console.log('at bt')
            // }

        }
    },

    methods: {
        readmorePdpa() {
            this.readmore_pdpa = true
        },
        onResize() {
           this.windowHeight = window.innerHeight
        }, 
        pdpa_update() {
            // this.pdpa_accept = 
            // //console.log(this.$store.state.register_form.pdpa)
            // this.pdpa_accept = this.$store.state.register_form.pdpa
        },
        checkPdpa() {
            if (this.pdpa_accept) {
                const user_type = this.$store.state.register_form.user_type
                const lineid = this.$store.state.register_form.lineid
                this.$store.commit('setRegisterForm', {
                    pdpa_accept: this.pdpa_accept,
                    user_type: user_type,
                    lineid: lineid,
                    highpressure: 0,
                    hyperlipidemia: 0,
                    alzheimer: 0,
                    heart: 0,
                    diabetes: 0,
                    arrhythmai: 0,
                    depress: 0,
                });
                this.$router.push('/simple_azq_start')
            }
        },
        goBack() {
            this.$router.push({
                path: '/select_azq',
                replace: true
            })
        }
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}
</style>
